import { logoData } from '@/data/marquee';
import Image from 'next/image';
import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Marquee from 'react-fast-marquee';


let settings = {
  dots: false,
  infinite: true,
  slidesToShow: 10,
  // slidesToScroll: 1,
  pauseOnHover: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  centerMode: true,
  centerPadding: '100px',
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },

    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true
      }
    }
  ]

};
interface BusinessMarqueeProps {
  title?: string;
}

const BusinessMarquee = ({ title }: BusinessMarqueeProps) => {
  return (
    <div className='trusted' style={{overflowX:'hidden'}}>
      <h3>{title ? title : 'Strong Relationships and Long-Term Thinking'}</h3>
      <Slider {...settings}>
        {logoData.map((logo) => (
          <div style={{ height: '50px' }} key={logo?.id}>
            <Image
              src={logo.image}
              width={logo.width}
              height={logo.height}
              priority={false}
              alt='business logo'
              style={{ objectFit: 'contain' }}
              className='img-fluid'
              key={logo.id}
            />
          </div>
        ))}
      </Slider>
      {/* <Marquee pauseOnHover={true} autoFill>
        {logoData.map((logo) => (
          <Image
            src={logo.image}
            width={logo.width}
            height={logo.height}
            priority={false}
            alt='business logo'
            style={{ objectFit: 'contain' }}
            className='img-fluid'
            key={logo.id}
          />
        ))}
      </Marquee> */}
    </div>
  );
};

export default React.memo(BusinessMarquee);
