export const logoData = [
  {
    id: 1,
    image: '/assets/client/Anota.png',
    width: 461,
    height: 109,
  },
  {
    id: 2,
    image: '/assets/client/AthabascaUniversity.png',
    width: 185,
    height: 56,
  },
  {
    id: 3,
    image: '/assets/client/BMJ.png',
    width: 110,
    height: 56,
  },
  {
    id: 4,
    image: '/assets/client/ByteMotion-new.png',
    width: 286,
    height: 65,
  },
  {
    id: 5,
    image: '/assets/client/Continusys-new.png',
    width: 1041,
    height: 203,
  },
  {
    id: 6,
    image: '/assets/client/eKool.png',
    width: 431,
    height: 138,
  },
  {
    id: 7,
    image: '/assets/client/Football5s-new.png',
    width: 2480,
    height: 2894,
  },
  {
    id: 8,
    image: '/assets/client/Foxchain.png',
    width: 191,
    height: 188,
  },
  {
    id: 9,
    image: '/assets/client/Jendamark.png',
    width: 380,
    height: 133,
  },
  {
    id: 10,
    image: '/assets/client/JustPlay.png',
    width: 435,
    height: 116,
  },
  {
    id: 11,
    image: '/assets/client/knowledge-academy-new.png',
    width: 369,
    height: 40,
  },
  {
    id: 12,
    image: '/assets/client/Logically-new.png',
    width: 158,
    height: 37,
  },
  {
    id: 13,
    image: '/assets/client/odin-education.png',
    width: 2611,
    height: 753,
  },
  {
    id: 14,
    image: '/assets/client/Prime.png',
    width: 1545,
    height: 513,
  },
  {
    id: 15,
    image: '/assets/client/scandlearn-new.png',
    width: 679,
    height: 89,
  },
  {
    id: 16,
    image: '/assets/client/Shoot-the-frame.png',
    width: 1280,
    height: 177,
  },
  {
    id: 17,
    image: '/assets/client/SNO-new.png',
    width: 300,
    height: 144,
  },
  {
    id: 18,
    image: '/assets/client/TechMahindra.png',
    width: 185,
    height: 56,
  },
  {
    id: 19,
    image: '/assets/client/OPIA-new.png',
    width: 1041,
    height: 203,
  }
];
